
import { VueEditor } from "vue3-editor";
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "ObservationsTab",
  components: {
    VueEditor,
  },

  setup() {
    const store = useStore();
    const action = computed(() => store.getters.Action);
    const toolBarConfig = ref([
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],

      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ]);
    const rawHTML = ref(action.value.observations);

    watch(
      () => rawHTML.value,
      (first) => {
        store.commit("setObservation", first);
      }
    );

    return {
      toolBarConfig,
      rawHTML,
    };
  },
});
