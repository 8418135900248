<template>
  <div class="tabs-container">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          id="overview"
          class="nav-link active"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('overview')"
          >{{ $t("maritimeFileOverview") }}</a
        >
      </li>
      <li class="nav-item">
        <a
          id="observations"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('observations')"
          >{{ $t("tobservations") }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, onUpdated, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "CRMTabs",
  emits: ["changeTab"],

  setup(props, { emit }) {
    const store = useStore();
    const action = computed(() => store.getters.Action);
    const actionTab = computed(() => store.getters.ActionTabItem);

    const handleTabClick = (tab) => {
      emit("changeTab", tab);
    };

    onUpdated(() => {
      if ("tab" in actionTab.value) {
        const overview = document.getElementById("overview");
        const tab = document.getElementById(actionTab.value.tab);
        tab.classList.add("active");
        overview.classList.remove("active");
        emit("changeTab", actionTab.value.tab);
        store.commit("removeActionTab");
      }
    });

    return {
      props,
      action,
      handleTabClick,
    };
  },
});
</script>
