
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import CRMTabs from "@/components/crm/CRMTabs.vue";
import OverviewTab from "@/components/crm/tabs/OverviewTab.vue";
import ObservationsTab from "@/components/crm/tabs/ObservationsTab.vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "CRMForm",
  components: {
    ObservationsTab,
    CRMTabs,
    OverviewTab,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const tab = ref("overview");
    const loadSelects = computed(() => store.getters.Load);
    const loading = computed(() => store.getters.loadingCRMElements);

    const selectedTab = (value) => {
      tab.value = value;
    };

    const loadData = (param) => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/actions/${param}`).then(({ data }) => {
        const payload = {
          id: data.id,
          entity_id: data.entity_id,
          office_id: data.office_id,
          type_id: data.type_id,
          status_id: data.status_id,
          reason_id: data.reason_id,
          result_id: data.result_id,
          description: data.description ? data.description : "",
          observations: data.observations,
          code: data.code,
          discharge_date: data.discharge_date,
          start_time: data.start_time,
          end_time: data.end_time,
          next_action_date: data.next_action_date,
          next_action_type_id: data.next_action_type_id,
          participant_id: data.participants
            .filter((x) => x.is_responsible === 0)
            .map((x) => x.id),
          responsible_id: data.participants
            .filter((x) => x.is_responsible === 1)
            .map((x) => x.id),
          files: data.files,
        };
        setTimeout(function () {
          store.commit("setAction", payload);
          store.commit("setLoadingc", false);
        }, 500);
        store.commit("setLoadingStatus", false);
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("crm", [{ route: "/crm_list", label: "crm" }]);
      const paramsRoute = route.params;
      if (Object.keys(paramsRoute).length > 0 && paramsRoute.id !== null) {
        if (loadSelects.value) {
          store.dispatch("loadElements");
        } else {
          loadData(paramsRoute.id);
        }
      } else {
        store.commit("setLoadingc", false);
      }
    });

    onUnmounted(() => {
      store.commit("resetModule");
      store.commit("resetCRMSelectModule");
    });

    return {
      tab,
      loading,
      selectedTab,
    };
  },
});
