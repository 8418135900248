
import { computed, onMounted, onUpdated, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "ParicipantCRMSelect",
  props: {
    participants_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:participant_id"],

  setup(props) {
    const { t } = useI18n();
    const element_id = ref("");
    const store = useStore();
    const participantsData = computed(() => store.getters.ParticipantsCRM);
    const inputElements = ref({
      loading: false,
      options: participantsData.value,
      list: participantsData.value,
    });

    const isRequired = (value) => {
      if (props.required && !value) {
        return t("rrequiredfields");
      }
      return true;
    };

    const { errorMessage, value } = useField("participant", isRequired);

    const selectParticipants = (query) => {
      if (query !== "") {
        inputElements.value.loading = true;
        setTimeout(() => {
          inputElements.value.loading = false;
          inputElements.value.options = inputElements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputElements.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/entities/lists`, {
              params: {
                per_page: 25,
                name: query,
                type_entity_ids: [1, 13],
              },
            }).then(({ data }) => {
              data.entities.forEach((item) => {
                store.commit("addParticipants", item);
              });
              inputElements.value.list = participantsData.value;
              inputElements.value.options = data.entities;
            });
          }
        }, 200);
      } else {
        inputElements.value.options = participantsData.value;
      }
    };

    watch(
      () => props.participants_id,
      (first) => {
        element_id.value = first;
      }
    );

    watch(
      () => participantsData.value,
      (first) => {
        inputElements.value.list = first;
        inputElements.value.options = first;
      }
    );

    onMounted(() => {
      inputElements.value.list = participantsData.value;
      inputElements.value.options = participantsData.value;
    });

    return {
      element_id,
      inputElements,
      errorMessage,
      value,
      selectParticipants,
    };
  },
};
