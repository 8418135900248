
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";
import store from "@/store";

export default {
  name: "EntityCRMSelect",
  props: {
    entity_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:entity_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const entityData = computed(() => store.getters.FilteredEntities);
    const inputEntity = ref({
      loading: false,
      options: entityData.value,
      list: entityData.value,
    });

    const isEntityRequired = (value) => {
      if (props.required && !value) {
        return t("rentity");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "entity",
      isEntityRequired
    );

    watch(
      () => props.entity_id,
      (first) => {
        element_id.value = first;
      }
    );

    watch(
      () => entityData.value,
      (first) => {
        inputEntity.value.options = first;
        inputEntity.value.list = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        element_id.value = props.entity_id;
      }, 500);
    });

    return {
      element_id,
      inputEntity,
      errorMessage,
    };
  },
};
