
import { Form, Field } from "vee-validate";
import OfficeSelect from "@/components/catalogs-select/OfficeSelect.vue";
import EntityCRMSelect from "@/components/catalogs-select/EntityCRMSelect.vue";
import ParicipantCRMSelect from "@/components/catalogs-select/ParicipantCRMSelect.vue";
import ActionTypeSelect from "@/components/catalogs-select/ActionTypeSelect.vue";
import ActionStatusSelect from "@/components/catalogs-select/ActionStatusSelect.vue";
import ActionReasonSelect from "@/components/catalogs-select/ActionReasonSelect.vue";
import ActionResultSelect from "@/components/catalogs-select/ActionResultSelect.vue";
import Upload2 from "@/components/upload/Upload2.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

interface FormCRM {
  id?: number;
  entity_id: number | undefined;
  office_id: number | undefined;
  type_id: number | undefined;
  status_id: number | undefined;
  reason_id: number | undefined;
  result_id: number | undefined;
  description: string;
  observations: string;
  discharge_date: string;
  start_time: string;
  end_time: string;
  next_action_date: string;
  next_action_type_id?: number;
  participant_id: string[];
  responsible_id: number;
  files: [];
}

export default {
  name: "OverviewTab",
  components: {
    OfficeSelect,
    ActionTypeSelect,
    ActionStatusSelect,
    ActionReasonSelect,
    ActionResultSelect,
    EntityCRMSelect,
    ParicipantCRMSelect,
    Upload2,
    Form,
    Field,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const id = ref(undefined);
    const action = computed(() => store.getters.Action);
    const officesList = computed(() => store.getters.Office);
    const filteredEntities = ref([]);
    const loading = ref(true);
    const form = ref<FormCRM>(action.value);

    watch(
      () => form.value.office_id,
      (first) => {
        store.commit("setLoading", false);
        ApiService.get(`api/offices/${first}`).then(({ data }) => {
          store.commit("setFilteredEntities", data.entities);
          store.commit("setParticipants", data.users);
        });
      }
    );

    const filterEntities = (value) => {
      form.value.office_id = value;
      const temp = officesList.value.find((x) => x.id === value);
      filteredEntities.value = temp.entities;
    };

    const onSubmit = (value) => {
      store.commit("setLoadingStatus", true);
      if (
        form.value.entity_id === null ||
        form.value.office_id === null ||
        form.value.type_id === null ||
        form.value.status_id === null ||
        form.value.reason_id === null ||
        form.value.result_id === null ||
        form.value.discharge_date === "" ||
        form.value.start_time === "" ||
        form.value.end_time === ""
      ) {
        ElNotification({
          title: "Error",
          message: t("rcrm"),
          type: "error",
        });
        return;
      }
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        const formData = new FormData();
        formData.append("id", `${form.value.id}`);
        formData.append("entity_id", `${form.value.entity_id}`);
        formData.append("office_id", `${form.value.office_id}`);
        formData.append("type_id", `${form.value.type_id}`);
        formData.append("status_id", `${form.value.status_id}`);
        formData.append("reason_id", `${form.value.reason_id}`);
        formData.append("result_id", `${form.value.result_id}`);
        formData.append("description", `${form.value.description}`);
        formData.append("observations", `${form.value.observations}`);
        formData.append("discharge_date", `${form.value.discharge_date}`);
        formData.append("start_time", `${form.value.start_time}`);
        formData.append("end_time", `${form.value.end_time}`);
        formData.append("next_action_date", `${form.value.next_action_date}`);
        formData.append(
          "next_action_type_id",
          form.value.next_action_type_id !== undefined
            ? `${form.value.next_action_type_id}`
            : ""
        );
        formData.append(
          "participant_id",
          JSON.stringify(form.value.participant_id)
        );
        formData.append("responsible_id", form.value.responsible_id.toString());
        //form.value.files.forEach((file) => formData.append("files", file));

        if (form.value.id === undefined) {
          ApiService.postAttach("api/actions", formData)
            .then(({ data }) => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              id.value = data.id;
              form.value.id = data.id;
              form.value.entity_id = data.entity_id;
              form.value.office_id = data.office_id;
              form.value.type_id = data.type_id;
              form.value.status_id = data.status_id;
              form.value.reason_id = data.reason_id;
              form.value.result_id = data.result_id;
              form.value.description = data.description ? data.description : "";
              form.value.observations = data.observations;
              form.value.discharge_date = data.discharge_date;
              form.value.start_time = data.start_time;
              form.value.end_time = data.end_time;
              form.value.next_action_date = data.next_action_date;
              form.value.next_action_type_id = data.next_action_type_id;
              form.value.participant_id = data.participants
                .filter((x) => x.is_responsible === 0)
                .map((x) => x.id);
              form.value.responsible_id = data.responsible.user_id;
              store.commit("setAction", form.value);
              store.commit("setLoadingStatus", false);
            })
            .catch(() => {
              store.commit("setLoadingStatus", false);
              submitButton.value?.removeAttribute("data-kt-indicator");
            });
        } else {
          ApiService.postAttach(`api/actions/update/${form.value.id}`, formData)
            .then(({ data }) => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              id.value = data.id;
              form.value.id = data.id;
              form.value.entity_id = data.entity_id;
              form.value.office_id = data.office_id;
              form.value.type_id = data.type_id;
              form.value.status_id = data.status_id;
              form.value.reason_id = data.reason_id;
              form.value.result_id = data.result_id;
              form.value.description = data.description;
              form.value.observations = data.observations;
              form.value.discharge_date = data.discharge_date;
              form.value.start_time = data.start_time;
              form.value.end_time = data.end_time;
              form.value.next_action_date = data.next_action_date;
              form.value.next_action_type_id = data.next_action_type_id;
              form.value.participant_id = data.participants
                .filter((x) => x.is_responsible === 0)
                .map((x) => x.id);
              form.value.responsible_id = data.responsible.user_id;
              store.commit("setAction", form.value);
              store.commit("setLoadingStatus", false);
            })
            .catch(() => {
              store.commit("setLoadingStatus", false);
              submitButton.value?.removeAttribute("data-kt-indicator");
            });
        }
      } else {
        store.commit("setLoadingStatus", false);
      }
    };

    const loadData = (param) => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/actions/${param}`).then(({ data }) => {
        store.commit("setAction", data);
        form.value.id = data.id;
        form.value.entity_id = data.entity_id;
        form.value.office_id = data.office_id;
        form.value.type_id = data.type_id;
        form.value.status_id = data.status_id;
        form.value.reason_id = data.reason_id;
        form.value.result_id = data.result_id;
        form.value.description = data.description ? data.description : "";
        form.value.observations = data.observations;
        form.value.discharge_date = data.discharge_date;
        form.value.start_time = data.start_time;
        form.value.end_time = data.next_action_date;
        form.value.next_action_type_id = data.next_action_type_id;
        form.value.participant_id = data.participants
          .filter((x) => x.is_responsible === 0)
          .map((x) => x.id);
        form.value.responsible_id = data.responsible.user_id;
        form.value.files = data.files;
        store.commit("setLoadingStatus", false);
      });
    };

    const createElement = () => {
      window.location.assign("/crm");
    };

    const findId = () => {
      loadData(id.value);
    };

    return {
      submitButton,
      id,
      loading,
      form,
      action,
      filteredEntities,
      findId,
      onSubmit,
      createElement,
      filterEntities,
    };
  },
};
