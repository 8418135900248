import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mx-xl-12 file-container" }
const _hoisted_2 = {
  key: 0,
  class: "tab-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CRMTabs = _resolveComponent("CRMTabs")!
  const _component_OverviewTab = _resolveComponent("OverviewTab")!
  const _component_ObservationsTab = _resolveComponent("ObservationsTab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CRMTabs, { onChangeTab: _ctx.selectedTab }, null, 8, ["onChangeTab"]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createVNode(_component_OverviewTab, null, null, 512), [
            [_vShow, _ctx.tab === 'overview']
          ]),
          _withDirectives(_createVNode(_component_ObservationsTab, null, null, 512), [
            [_vShow, _ctx.tab === 'observations']
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}